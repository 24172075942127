/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

            },
            finalize: function () {


                // Animation,
                // JavaScript to be fired on all pages, after page specific JS is fired
                window.sr = ScrollReveal({
                    // reset: true
                });
                if ($('.animation').length > 0) {
                    sr.reveal(".animation", {
                        origin: origin,
                        delay: delay,
                        duration: duration,
                        distance: distance,
                        easing: easing,
                        scale: scale,

                    });
                }
                if ($('.animation-sequenced').length > 0) {
                    sr.reveal(".animation-sequenced", {
                        origin: origin,
                        delay: delay,
                        duration: duration,
                        distance: distance,
                        easing: easing,
                        scale: scale,

                    }, sequenced);

                }
                $('.full_width_layouts.carousel ').slick({
                    arrows: true

                });


                // var animations = $(".animation-sequenced");
                //
                // $(animations).each(function (index, value) {
                //     sr.reveal($(this), {
                //         origin: origin,
                //         delay: delay,
                //         duration: duration,
                //         distance: distance,
                //         easing: easing,
                //         scale: scale,
                //
                //     },sequenced);
                // });


                var phantomHeight = $('header').outerHeight();
                // Set the height of $sticky-phantom
                $('#sticky-phantom').height(phantomHeight).show();
                $(phantomHeight).css('height', '-10px');


                $(".slidingDiv").hide();
                $('.show_hide').on("click", function () {
                    $(this).children('.draw_text')
                        .toggleClass('rotate');
                    $(this).children('.slidingDiv')
                        .slideToggle();
                });


                var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
                var is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
                var is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
                var is_safari = navigator.userAgent.indexOf("Safari") > -1;
                var is_opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
                if (is_safari) {
                    // console.log('Safari');
                    $('body').addClass('safari');
                }
                if (is_chrome) {
                    // console.log('Chrome');
                    $('body').addClass('chrome');
                }
                if (is_firefox) {
                    //  console.log('Firefox');
                    $('body').addClass('firefox');
                }
                if (is_explorer) {
                    //  console.log('Explorer');
                    $('body').addClass('explorer');
                }
                if (is_opera) {
                    //  console.log('Opera');
                    $('body').addClass('opera');
                }


// Add smooth scrolling to all links
                $('a[href*=#]').on('click', function (event) {

                    // Make sure this.hash has a value before overriding default behavior
                    if (this.hash !== "") {
                        // Prevent default anchor click behavior
                        event.preventDefault();

                        // Store hash
                        var hash = this.hash;

                        // Using jQuery's animate() method to add smooth page scroll
                        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                        $('html, body').animate({
                            scrollTop: $(hash).offset().top - 100
                        }, 800, function () {

                            // Add hash (#) to URL when done scrolling (default click behavior)
                            window.location.hash = hash;
                        });
                    } // End if
                });

                //LIGHTBOX

                $('.gallery-item').magnificPopup({
                    type: 'image',
                    closeOnContentClick: true,
                    disableOn: 0,
                    mainClass: 'mfp-fade',
                    gallery: {
                        enabled: true,
                        preload: [0, 2]
                    }
                });

                //looks for iframes wraps and adapts the height and width
                (function (window, document, undefined) {

                    /*
                     * Grab all iframes on the page or return
                     */
                    var iframes = document.getElementsByTagName('iframe');

                    /*
                     * Loop through the iframes array
                     */
                    for (var i = 0; i < iframes.length; i++) {

                        var iframe = iframes[i],

                            /*
                             * RegExp, extend this if you need more players
                             */
                            players = /www.youtube.com|player.vimeo.com/;

                        /*
                         * If the RegExp pattern exists within the current iframe
                         */
                        if (iframe.src.search(players) > 0) {

                            /*
                             * Calculate the video ratio based on the iframe's w/h dimensions
                             */
                            var videoRatio = (iframe.height / iframe.width) * 100;

                            /*
                             * Replace the iframe's dimensions and position
                             * the iframe absolute, this is the trick to emulate
                             * the video ratio
                             */
                            iframe.style.position = 'absolute';
                            iframe.style.top = '0';
                            iframe.style.left = '0';
                            iframe.width = '100%';
                            iframe.height = '100%';

                            /*
                             * Wrap the iframe in a new <div> which uses a
                             * dynamically fetched padding-top property based
                             * on the video's w/h dimensions
                             */
                            var wrap = document.createElement('div');
                            wrap.className = 'fluid-vids';
                            wrap.style.width = '100%';
                            wrap.style.position = 'relative';
                            wrap.style.paddingTop = videoRatio + '%';

                            /*
                             * Add the iframe inside our newly created <div>
                             */
                            var iframeParent = iframe.parentNode;
                            iframeParent.insertBefore(wrap, iframe);
                            wrap.appendChild(iframe);

                        }

                    }

                })(window, document);

            },


        },
        // Home page

        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        // Flexible page
        'flexible': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
